<template>
	<div>
		<b-row>
			<b-col cols="12" class="mb-2">
				<h3 class="mb-0 text-center titulo-tab">
					Documentos Necessários
				</h3>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cedente (quem está vendendo):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples)</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjugue do Cedente (em caso de Cedente Casado):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples).</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de residência. (Caso o documento não esteja acessivel, será possivel informar via whatsApp ou E-mail)</p>
				</div>
			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjugue do Cedente (em caso de Cedente Casado):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cessionário (quem está Comprando):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão de Nascimento (atualizada) ou Casamento(cópia simples).</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de residência atualizado. (Caso o documento não esteja acessivel, será possivel informar via whatsApp ou E-mail)</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - A partir de agora, aceitaremos qualquer documento que comprove o domicílio do cliente, independentemente da data de emissão. Se o comprador não tiver um documento acessível, ele pode informar seu endereço via WhatsApp ou e-mail.</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - O e-mail ou print da conversa no WhatsApp servirá como comprovante para validação da venda.</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - As informações enviadas devem conter todos os detalhes necessários para o preenchimento da proposta, incluindo: CEP, logradouro, número, bairro, cidade, estado e, se necessário, complemento (como quadra e lote para casas sem número ou nome do condomínio e número do apartamento para condomínios).</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - O setor de contratos utilizará os documentos fornecidos para validar as vendas, portanto, é essencial que os documentos estejam legíveis. Áudios enviados via WhatsApp não serão aceitos.</p>
				</div>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Documentos do Cônjuge do Cessionário (opcional):
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - A assinatura do cônjuge no contrato de compra e venda agora é opcional para o cliente, com exceção dos contratos do Hectares.</p>
				</div>
				
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CNH</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Profissão.</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Caso o cliente escolha incluir a assinatura do cônjuge, toda a documentação exigida anteriormente deverá ser apresentada.</p>
				</div>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" class="mb-2">
				<h6>
					Caso o contrato esteja no nome de Pessoa Jurídica, enviar também:
				</h6>
			</b-col>
			<b-col cols="12" class="mb-2">
				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Contrato Social</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Última Alteração Contratual</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Cartão CNPJ e QSA</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Certidão Simplificada</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - Comprovante de Endereço atualizado</p>
				</div>

				<div class="d-flex text-left my-1 align-items-center">
					<p class="requisito"> - CND de Condomínio</p>
				</div>

			</b-col>
		</b-row>

	</div>
</template>
<style scoped>
.requisito {
	margin: 0;
	width: 90%;
}

.titulo-tab {
	margin-bottom: 50px!important;
}
</style>

<script>
import {
	BRow,
	BCol,
} from 'bootstrap-vue'

export default {
	name: 'TabDocumentos',
	components: {
		BRow,
		BCol,
	},
}
</script>
