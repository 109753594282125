<template>
   <div>
      <b-row>
         <b-col cols="12" class="mb-2">
            <h3 class="mb-0 text-center">
               Transferência de Titularidade
            </h3>
         </b-col>
      </b-row>
      <b-row>
         <b-col cols="12" class="mb-2 titulo-tab">
            <h6 class="text-center">
               O que é Transferência de titularidade?
            </h6>
         </b-col>
      </b-row>
      <b-row>
         <b-col cols="12" class="mb-2">
            <p class="text-justify">
               Chamamos o processo de transferência de titularidade do contrato de <b>Processo de Cessão de
                  Direitos</b>,
               onde o
               cedente (titular atual) cede os direitos do bem para o cessionário (novo comprador), sejam as partes
               representadas por pessoa física ou jurídica.
            </p>
         </b-col>
      </b-row>

      <b-row>
         <b-col cols="12" class="mb-2">
            <h6 class="text-center">
               A Transferência tem algum custo?
            </h6>
         </b-col>
      </b-row>

      <b-row>
         <b-col cols="12" class="mb-2">
            <p class="text-justify">
               Sim, após a conferência dos documentos, nós emitimos um boleto da Taxa Administrativa e enviamos em seu
               e-mail, ela corresponde ao valor de <b>2% do contrato</b>, em seguida, com a baixa do pagamento, basta
               fazer
               a
               validação com um PIX de <b>R$0,01</b> (um centavo) e realizar a assinatura digital.
            </p>
         </b-col>
      </b-row>

      <b-row>
			<b-col cols="12" class="mb-2">
				<h6 class="text-center">
					Requisitos para a Transferência de Titularidade
				</h6>
			</b-col>
		</b-row>
		<div class="d-flex text-left my-1 align-items-center">
			<p class="requisito"> - Estar adimplente com o financiamento.</p>
		</div>

		<div class="d-flex text-left my-1 align-items-center">
			<p class="requisito"> - Não possuir débitos com o município.</p>
		</div>

		<div class="d-flex text-left my-1 align-items-center">
			<p class="requisito"> - O Lote não pode estar quitado.</p>
		</div>
	</div>
</template>

<script>
import {
   BRow,
   BCol,
} from 'bootstrap-vue'
export default {
   name: 'TabInicio',
   components: {
      BRow,
      BCol,
   },

   data() {
      return {
      }
   },
   methods: {
   },

   computed: {
   }
}
</script>
